<template>
  <div class="hr-icon flex aspect-square items-center justify-center">
    <component
      :is="icon"
      v-if="!isCustomIcon && icon"
      :class="{
        'text-xs': size === ComponentSize.sm,
        'text-base': size === ComponentSize.default,
        'text-xl': size === ComponentSize.lg,
        'text-2xl': size === ComponentSize.xl,
        'text-[28px]': size === ComponentSize.xl2,
        'text-3xl': size === ComponentSize.xl3,
        'text-[32px]': size === ComponentSize.xl4,
        'text-5xl': size === ComponentSize.xl5,
        'text-6xl': size === ComponentSize.xl6,
      }"
      :weight="weight"
    />
    <Suspense v-else-if="icon">
      <nuxt-icon
        class="[&_svg]:mb-0"
        :name="icon.toString()"
        :filled="filled"
        :class="{
          'text-xs': size === ComponentSize.sm,
          'text-base': size === ComponentSize.default,
          'text-xl': size === ComponentSize.lg,
          'text-2xl': size === ComponentSize.xl,
          'text-[28px]': size === ComponentSize.xl2,
          'text-3xl': size === ComponentSize.xl3,
          'text-[32px]': size === ComponentSize.xl4,
          'text-5xl': size === ComponentSize.xl5,
          'text-6xl': size === ComponentSize.xl6,
        }"
      />
      <template #fallback>
        <div class="border-foreground-secondary h-full w-full rounded-full border-2" />
      </template>
    </Suspense>
    <div v-else>
      <div class="border-foreground-secondary h-full w-full rounded-full border-2" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    PhArrowCircleUp,
    PhArrowCounterClockwise,
    PhArrowDown,
    PhArrowElbowDownLeft,
    PhArrowLeft,
    PhArrowRight,
    PhArrowsClockwise,
    PhArrowsCounterClockwise,
    PhArrowsDownUp,
    PhArrowsLeftRight,
    PhArrowUDownLeft,
    PhArrowUp,
    PhArrowUpRight,
    PhAt as PhAtSign,
    PhBarcode,
    PhBinoculars,
    PhBoat,
    PhBroadcast,
    PhBrowsers,
    PhCalendarBlank,
    PhCameraSlash,
    PhCaretDown,
    PhCaretRight,
    PhCaretUp,
    PhChartDonut,
    PhCheck,
    PhCheckCircle,
    PhCheckSquareOffset,
    PhCirclesThreePlus,
    PhClipboard,
    PhClipboardText,
    PhClock,
    PhClockCounterClockwise,
    PhX as PhClose,
    PhCloudArrowDown,
    PhCloudX,
    PhCopy,
    PhCreditCard,
    PhCubeTransparent,
    PhDotsNine,
    PhDotsThreeVertical,
    PhEnvelope,
    PhWarningDiamond as PhError,
    PhExclamationMark,
    PhExport,
    PhEye,
    PhFile,
    PhFileArrowDown,
    PhFileCsv,
    PhFilePdf,
    PhFileText,
    PhFileXls,
    PhFlag,
    PhFlowArrow,
    PhFunnelSimple,
    PhGear,
    PhGlobe,
    PhGlobeSimple,
    PhGps,
    PhHouse,
    PhImages,
    PhInfo,
    PhLifebuoy,
    PhLightbulb,
    PhLink,
    PhLockSimple,
    PhMagnifyingGlass,
    PhMapPin,
    PhMapPinSimpleLine,
    PhNewspaper,
    PhNote,
    PhNotification,
    PhNut,
    PhPackage,
    PhPalette,
    PhPaperclip,
    PhPaperPlaneRight,
    PhPencilSimple,
    PhPencilSimpleLine,
    PhPhone,
    PhPlay,
    PhPlug,
    PhPlus,
    PhPrinter,
    PhProhibit,
    PhQuestion,
    PhReceipt,
    PhShippingContainer,
    PhShoppingBagOpen,
    PhSidebar,
    PhSignIn,
    PhSmiley,
    PhSquareSplitHorizontal,
    PhStamp,
    PhTagSimple,
    PhTrash,
    PhTrashSimple,
    PhTray,
    PhTruck,
    PhUserCircle,
    PhUsers,
    PhWarehouse,
    PhWarning,
    PhWarningCircle,
    PhWarningDiamond,
    PhWifiHigh,
    PhXCircle,
  } from "@phosphor-icons/vue/compact";
  import { ComponentSize } from "~/types/global";

  type Weight = "bold" | "duotone" | "fill" | "light" | "regular";
  export type IconSource = `${Weight}/${string}`;
  export type CustomIconSource = `custom/${IconSource}`;

  const icons: Record<string, Component> = {
    PhFunnelSimple,
    PhArrowsLeftRight,
    PhTruck,
    PhXCircle,
    PhCameraSlash,
    PhSquareSplitHorizontal,
    PhArrowsDownUp,
    PhQuestion,
    PhHouse,
    PhStamp,
    PhEye,
    PhCalendarBlank,
    PhPackage,
    PhClipboard,
    PhCheckCircle,
    PhWarehouse,
    PhArrowUpRight,
    PhChartDonut,
    PhCirclesThreePlus,
    PhNewspaper,
    PhArrowRight,
    PhPrinter,
    PhFileArrowDown,
    PhCubeTransparent,
    PhFlag,
    PhProhibit,
    PhTagSimple,
    PhWarningCircle,
    PhPhone,
    PhArrowCounterClockwise,
    PhArrowsClockwise,
    PhClockCounterClockwise,
    PhEnvelope,
    PhDotsThreeVertical,
    PhBinoculars,
    PhPlus,
    PhArrowLeft,
    PhCopy,
    PhMagnifyingGlass,
    PhCheck,
    PhDotsNine,
    PhCaretDown,
    PhInfo,
    PhClose,
    PhCloudX,
    PhFileText,
    PhExport,
    PhWarningDiamond,
    PhImages,
    PhError,
    PhPencilSimpleLine,
    PhAtSign,
    PhPaperclip,
    PhSmiley,
    PhClock,
    PhPaperPlaneRight,
    PhArrowCircleUp,
    PhNut,
    PhReceipt,
    PhClipboardText,
    PhTray,
    PhPlug,
    PhCheckSquareOffset,
    PhLockSimple,
    PhUsers,
    PhGlobe,
    PhShoppingBagOpen,
    PhCreditCard,
    PhPalette,
    PhCaretUp,
    PhFilePdf,
    PhFileXls,
    PhCaretRight,
    PhLink,
    PhFile,
    PhFileCsv,
    PhFlowArrow,
    PhLifebuoy,
    PhPlay,
    PhGlobeSimple,
    PhSignIn,
    PhPencilSimple,
    PhTrashSimple,
    PhTrash,
    PhUserCircle,
    PhArrowsCounterClockwise,
    PhFileXml: PhFile,
    PhNotification,
    PhLightbulb,
    PhArrowUp,
    PhArrowDown,
    PhArrowElbowDownLeft,
    PhArrowUDownLeft,
    PhSidebar,
    PhGear,
    PhExclamationMark,
    PhBoat,
    PhShippingContainer,
    PhMapPinSimpleLine,
    PhMapPin,
    PhBarcode,
    PhCloudArrowDown,
    PhNote,
    PhWifiHigh,
    PhGps,
    PhBroadcast,
    PhWarning,
    PhBrowsers,
  };

  defineOptions({
    name: "Icon",
  });

  const props = withDefaults(
    defineProps<{
      size?: IconSize;
      src?: IconSource | CustomIconSource | string | "undefined" | null;
      filled?: boolean;
    }>(),
    {
      size: ComponentSize.default,
      src: "",
      filled: false,
    }
  );

  const icon = computed(() => {
    if (!props.src) return null;
    if (props.src === "undefined") return "Question";
    if (props.src.startsWith("custom/")) return customIconPath.value;

    let name = props.src;

    if (name.includes("/")) {
      name = name.split("/")[1];
    }
    if (name.includes(".svg")) {
      name = name.split(".svg")[0];
    }
    name = name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("");

    //check if the icon exists in the icons object
    if (!icons[`Ph${name}`]) {
      console.error(`Icon Ph${name} does not exist, please make sure the icon is imported in the Icon component.`);
      return name;
    }
    return icons[`Ph${name}`];
  });

  const isCustomIcon = computed(() => {
    if (!props.src) return false;
    return props.src.startsWith("custom/") || typeof icon.value === "string" || icon.value instanceof String;
  });

  const customIconPath = computed(() => {
    if (!props.src) return false;

    return props.src.replace("custom/", "");
  });

  const weight = computed<Weight>(() => {
    if (!props.src) return "fill";
    if (props.filled) return "fill";

    const style = props.src.split("/")[0];
    switch (style) {
      case "bold":
        return "bold";
      case "duotone":
        return "duotone";
      case "fill":
        return "fill";
      case "light":
        return "light";
      case "regular":
        return "regular";
      default:
        return "bold";
    }
  });
</script>
