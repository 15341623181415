<template>
  <div class="flex flex-col gap-1">
    <label v-if="label" class="text-body-sm-heavy text-secondary text-left">{{ label }}</label>

    <div>
      <InputText
        ref="input"
        v-model="number"
        :message="t('set_country_prefix')"
        :name="name"
        :placeholder="t('phone')"
        @update:model-value="updatePhone"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  const { t } = useI18n();

  const emit = defineEmits(["update:modelValue"]);

  const props = defineProps<{
    name?: string;
    label?: string;
    values?: any;
    setPrefix?: any;
  }>();

  onMounted(() => {
    if (props.values) {
      number.value = props.values.phone_number;
    }
  });

  watch(
    () => props.setPrefix,
    (val, oldVal) => {
      if (val && phonePrefixes.value) {
        const newPrefix = phonePrefixes.value.prefixes.find((prefix) => prefix.iso === val)?.value;
        const oldPrefix = phonePrefixes.value.prefixes.find((prefix) => prefix.iso === oldVal)?.value;
        if (!newPrefix || !oldPrefix) return;
        number.value = input.value?.inputValue ? input.value.inputValue.replace(oldPrefix, newPrefix) : newPrefix;
        input.value?.setValue(number.value);
      }
    }
  );

  const { data: phonePrefixes } = await useCountries();

  const number = ref("");
  const input = useTemplateRef("input");

  const updatePhone = () => {
    emit("update:modelValue", { phone_number: number.value });
  };
</script>
