<template>
  <div
    class="main-input flex flex-col gap-1"
    :class="[{ error: showError, 'text-disabled': readonly }, props.class]"
    @click="() => emit('click')"
  >
    <label v-if="label" class="text-body-sm-heavy text-secondary flex items-center gap-2 text-left"
      >{{ label }} <slot name="label_suffix"
    /></label>
    <div
      ref="wrapper"
      class="input-wrapper flex items-center transition-all duration-300"
      :class="[
        {
          'h-9 rounded-xl py-2': size === ComponentSize.default && tag == 'input',
          'pr-3.5 pl-4': size !== ComponentSize.sm && tag == 'input' && !equalPadding && !slots.button,
          'pr-1 pl-4': size !== ComponentSize.sm && tag == 'input' && !equalPadding && slots.button,

          'px-2': size === ComponentSize.default || (size === ComponentSize.lg && tag == 'input' && equalPadding),
          'rounded-lg px-3 py-[3px]': size === ComponentSize.sm && tag == 'input',
          'h-11 rounded-xl py-3': size === ComponentSize.lg && tag == 'input',

          'rounded-xl py-2 pl-3': tag == 'textarea',
          readonly,
        },
        !darkBg ? 'bg-00' : 'bg-01',
      ]"
    >
      <div
        v-if="prefix || slots.prefix"
        ref="prefixWrapper"
        class="text-quarterary mr-2 flex h-full items-center text-sm font-normal"
      >
        <slot name="prefix">
          {{ prefix }}
        </slot>
      </div>
      <slot>
        <input
          v-if="tag == 'input'"
          v-bind="$attrs"
          ref="input"
          v-model="modelValue"
          class="h-5 w-full truncate"
          :type="type"
          :name="name"
          :class="{
            'bg-00': !darkBg,
            'bg-01': darkBg,
            'mr-2': suffix || slots.suffix,
            'mr-1': !suffix && !slots.suffix,
            'text-right': textRight,
          }"
          :readonly="readonly"
          :min="type === 'number' ? min : undefined"
          :max="type === 'number' ? max : undefined"
          :minlength="type === 'text' ? min : undefined"
          :maxlength="type === 'text' ? max : undefined"
          @blur="onBlur"
          @focus="hasFocus = true"
        />
      </slot>

      <div
        v-if="suffix || slots.suffix"
        ref="suffixWrapper"
        class="text-quarterary ml-2 flex h-full w-full max-w-max items-center text-sm font-normal"
      >
        <slot name="suffix">
          {{ suffix }}
        </slot>
      </div>
      <slot name="button" />

      <textarea
        v-if="tag == 'textarea'"
        v-bind="$attrs"
        ref="input"
        v-model="modelValue"
        class="w-full"
        :class="[!darkBg ? 'bg-00' : 'bg-01', size == ComponentSize.lg ? 'h-[200px]' : 'h-[100px]']"
        :readonly="readonly"
        @blur="() => emit('blur')"
      />
    </div>
    <Message v-if="showError" variant="invalid">{{ error }}</Message>
    <Message v-if="message" :variant="ButtonVariant.Default">{{ message }}</Message>
  </div>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";

  const slots = useSlots();
  defineOptions({
    name: "InputWrapper",
  });

  const [modelValue, modifiers] = defineModel<string | number | boolean>({
    set(value) {
      const m = getModifiers(modifiers, value);
      if (m.subdomain) {
        return companyNameSanitizer(value);
      }
      if (typeof value === "string") {
        if (m.trim) {
          return value.trim();
        }
        if (m.noWhitespace) {
          return value.replace(/\s/g, "");
        }
        if (m.multi) {
          //split on newline and if only one line, return string in array
          let v = [];
          if (props.type === "text") {
            v = value.split(",").length > 1 ? value.split(",") : [value];
          } else {
            v = value.split("\n").length > 1 ? value.split("\n") : [value];
          }
          //remove empty lines except for the last one
          return v
            .map((line, idx) => {
              if (idx < v.length - 1) {
                return line.trim();
              }
              return line;
            })
            .filter((line, idx) => line || idx === v.length - 1);
        }
      }

      return value;
    },
    get(value) {
      if (!value) return value;
      const m = getModifiers(modifiers, value);

      if (m.multi && Array.isArray(value)) {
        if (props.type === "text") {
          return value.join(",");
        }
        return value.join("\n");
      }
      return value;
    },
  });
  const input = ref<HTMLElement | null>(null);
  const wrapper = ref<HTMLElement | null>(null);
  const prefixWrapper = ref<HTMLElement | null>(null);
  const suffixWrapper = ref<HTMLElement | null>(null);
  const hasFocus = ref(false);
  const props = withDefaults(
    defineProps<{
      type?:
        | "button"
        | "checkbox"
        | "color"
        | "date"
        | "datetime-local"
        | "email"
        | "file"
        | "hidden"
        | "image"
        | "month"
        | "number"
        | "password"
        | "radio"
        | "range"
        | "reset"
        | "search"
        | "submit"
        | "tel"
        | "text"
        | "time"
        | "url"
        | "week";
      tag?: "input" | "textarea";
      label?: string | null;
      error?: string | null;
      size?: InputSize;
      prefix?: string | null;
      suffix?: string | null;
      modifiers?: string[];
      class?: string | null | object | Array<string | object>;
      autofocus?: boolean;
      readonly?: boolean;
      darkBg?: boolean;
      name?: string;
      equalPadding?: boolean;
      message?: string;
      textRight?: boolean;
      min?: number;
      max?: number;
    }>(),
    {
      type: "text",
      tag: "input",
      size: ComponentSize.default,
      darkBg: false,
      name: "",
      equalPadding: false,
      modifiers: () => [],
      error: undefined,
      label: undefined,
      prefix: undefined,
      suffix: undefined,
      class: undefined,
      message: undefined,
      textRight: false,
      min: undefined,
      max: undefined,
    }
  );

  const emit = defineEmits(["click", "blur"]);

  const getModifiers = (modifiers, value): { [key: string]: any } => {
    //transform props.modifiers to object with true values for each modifier
    let m = props.modifiers
      ? props.modifiers.reduce((acc, mdf) => {
          acc[mdf] = true;
          return acc;
        }, {})
      : {};

    //merge with modelmodifiers
    m = Object.assign(m, modifiers.value, { value });

    return m;
  };

  onMounted(() => {
    if (props.autofocus) {
      setTimeout(() => {
        input.value?.focus();
      }, 0);
    }

    //If prefix or suffix is present, and they are not buttons or anchors, focus input on click
    if (
      prefixWrapper.value &&
      !prefixWrapper.value.querySelector("button") &&
      !prefixWrapper.value.querySelector("a")
    ) {
      prefixWrapper.value.addEventListener("click", () => {
        input.value?.focus();
      });
    }
    if (
      suffixWrapper.value &&
      !suffixWrapper.value.querySelector("button") &&
      !suffixWrapper.value.querySelector("a")
    ) {
      suffixWrapper.value.addEventListener("click", () => {
        input.value?.focus();
      });
    }
  });

  const onBlur = (evt: FocusEvent) => {
    emit("blur", evt);
    hasFocus.value = false;
  };

  const showError = computed(() => {
    return !!props.error && !hasFocus.value && !props.readonly && modelValue.value !== "";
  });

  defineExpose({
    input,
    wrapper,
  });
</script>
