export interface IPrefix {
  label: string;
  value: string;
  iso: string;
}

export default async () => {
  const data = ref<{
    prefixes: IPrefix[];
  }>({
    prefixes: [],
  });
  const loading = ref(false);
  const fetchCountries = () => {
    loading.value = true;
    homeFetch("countries")
      .then((r) => {
        data.value = {
          prefixes:
            r.data?.map((country) => ({
              label: "+" + country.phone_prefix,
              value: "+" + country.phone_prefix,
              iso: country.iso,
            })) || [],
        };
      })
      .finally(() => {
        loading.value = false;
      });
  };

  fetchCountries();
  return {
    data,
    pending: loading,
  };
};
