<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <FlowSection
      :title="t('settings.integration.api_key.label.title')"
      :description="t('settings.integration.api_key.label.description')"
    >
      <div class="mb-3 flex flex-col justify-between gap-4">
        <InputText
          class="text-secondary"
          :model-value="username"
          readonly
          :size="ComponentSize.lg"
          :label="t('username')"
        >
          <template #button>
            <Button @click="() => copyToClipboard(username, t('settings.integration.username.copied_to_clipboard'))">
              <template #prefix>
                <Icon src="copy" />
              </template>
              {{ t("copy") }}
            </Button>
          </template>
        </InputText>
        <InputText class="text-secondary" :model-value="token" readonly :size="ComponentSize.lg" :label="t('api_key')">
          <template #button>
            <Button @click="() => copyToClipboard(token, t('settings.integration.api_key.copied_to_clipboard'))">
              <template #prefix>
                <Icon src="copy" />
              </template>
              {{ t("copy") }}
            </Button>
          </template>
        </InputText>
      </div>
      <div>
        <Button :variant="ButtonVariant.PrimaryLink" @click="toggleAdvanced">
          {{ t("advanced") }}
          <template #suffix>
            <Icon :src="advancedWrapperVisible ? 'CaretUp' : 'CaretDown'" :size="ComponentSize.sm" />
          </template>
        </Button>
        <div ref="advancedWrapper" class="p-3" :class="{ hidden: !advancedWrapperVisible }">
          <div class="text-body-lg-heavy">{{ t("new") }} {{ t("settings.integration.api_key.label.title") }}</div>
          <div class="flex justify-between gap-3">
            <div class="text-body-default text-quarterary">
              {{ t("settings.integration.api_key.new.description") }}
            </div>
            <Button
              class="shrink-0"
              :variant="ButtonVariant.Destructive"
              :loading="generatingToken"
              @click="generateToken"
            >
              {{ t("settings.integration.api_key.new.generate") }}
            </Button>
          </div>
        </div>
      </div>
    </FlowSection>

    <Divider />

    <FlowSection :title="t('documentation')">
      <template #headerButtons>
        <Button href="https://docs.homerunner.com/" target="_blank">
          {{ t("read_more") }}
          <template #suffix>
            <Icon src="ArrowUpRight" />
          </template>
        </Button>
      </template>
    </FlowSection>
    <NotificationWrapper />
  </div>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import type { ISettingsFlow } from "../types";

  import NotificationWrapper from "../components/NotificationWrapper.vue";

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
    }>(),
    {
      currentHeader: null,
    }
  );
  const { t } = useI18n();
  const token = ref<string>("");
  const username = ref<string>("");
  const advancedWrapper = ref<HTMLElement | null>(null);
  const advancedWrapperVisible = ref<boolean>(false);
  const generatingToken = ref<boolean>(false);

  const fetchToken = () => {
    setLoading(true);
    return homeFetch("workspace/integrations/token")
      .then((response) => {
        if (response?.data) {
          token.value = response.data.token;
          username.value = response.data.email;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateToken = () => {
    const { accountName } = storeToRefs(useWorkspacesStore());
    $prompt({
      message: t("settings.integration.api_key.new.confirm", { organisation: accountName.value }),
      placeholder: t("settings.integration.api_key.new.placeholder"),
      prompt: accountName.value,
      confirmText: t("settings.integration.api_key.new.generate"),
      destructive: true,
      onConfirm: () => {
        generatingToken.value = true;
        homeFetch("workspace/integrations/update-token", {
          method: "put",
        })
          .then((response) => {
            if (response?.data) {
              token.value = response.data;
              $toast.add({
                icon: "check",
                title: t("settings.integration.api_key.new.success"),
                target: "#settings-modal .notification-wrapper",
              });
            }
          })
          .finally(() => {
            generatingToken.value = false;
          });
      },
    });
  };

  const toggleAdvanced = () => {
    if (advancedWrapper.value) {
      advancedWrapperVisible.value = !advancedWrapperVisible.value;
    }
  };

  onMounted(() => {
    fetchToken().then(() => {
      goToCurrentHeader();
    });
  });

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);
</script>

<script lang="ts">
  export const useIntegrationSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const { hasCapability } = useCapabilities();
    const icon = "regular/plug";
    const title = t("settings.integration.title");
    const pageTitle = t("settings.integration.title");
    const key = "integration";
    const condition = computed(() => hasCapability("account.api"));

    return {
      icon,
      pageTitle,
      title,
      key,
      condition,
    };
  };
</script>
