import payload_plugin_qLmFnukI99 from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/app/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_7iiyayj7ew from "/app/modules/dialog/runtime/plugin.ts";
import plugin_58xPIPqJLa from "/app/modules/userSettings/runtime/plugin.ts";
import plugin_De8PEtVzlK from "/app/modules/workspaceSettings/runtime/plugin.ts";
import plugin_CSkeqsKc0z from "/app/modules/createticket/runtime/plugin.ts";
import plugin_vkaTIRAeM0 from "/app/modules/printing/runtime/plugin.ts";
import plugin_RBRENgz87X from "/app/modules/inviteusers/runtime/plugin.ts";
import plugin_sm8ZcHzXmP from "/app/modules/newshipment/runtime/plugin.ts";
import plugin_HSPC7SS8U2 from "/app/modules/formwizard/runtime/plugin.ts";
import plugin_eexrVgN5tP from "/app/modules/boarding/runtime/plugin.ts";
import plugin_Bz07CbKbST from "/app/modules/loader/runtime/plugin.ts";
import plugin_DvPziDwt5Z from "/app/modules/toast/runtime/plugin.ts";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_PSbK9A48ZQ from "/app/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import floating_vue_EIcJ7xiw0h from "/app/.nuxt/floating-vue.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import eventbus_8iEFQ4ahH5 from "/app/plugins/eventbus.ts";
import flare_client_8OewLYGJqN from "/app/plugins/flare.client.ts";
import quill_rR0rVhYhH9 from "/app/plugins/quill.ts";
import recaptcha_W4US4xmBV6 from "/app/plugins/recaptcha.js";
import route_client_8A0ENhmQRG from "/app/plugins/route.client.ts";
import vue_final_modal_pML93k5qcp from "/app/plugins/vue-final-modal.ts";
import ssg_detect_3fHkBxLtv0 from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  plugin_vue3_YdLad5Mpq3,
  plugin_7iiyayj7ew,
  plugin_58xPIPqJLa,
  plugin_De8PEtVzlK,
  plugin_CSkeqsKc0z,
  plugin_vkaTIRAeM0,
  plugin_RBRENgz87X,
  plugin_sm8ZcHzXmP,
  plugin_HSPC7SS8U2,
  plugin_eexrVgN5tP,
  plugin_Bz07CbKbST,
  plugin_DvPziDwt5Z,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_PSbK9A48ZQ,
  floating_vue_EIcJ7xiw0h,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  eventbus_8iEFQ4ahH5,
  flare_client_8OewLYGJqN,
  quill_rR0rVhYhH9,
  recaptcha_W4US4xmBV6,
  route_client_8A0ENhmQRG,
  vue_final_modal_pML93k5qcp,
  ssg_detect_3fHkBxLtv0
]