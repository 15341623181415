import { defineStore } from "pinia";
import type { IOption } from "@/types/options";

export const useOptionsStore = defineStore(
  "Options",
  () => {
    const options = ref<IOption[]>([]);

    const setOptions = (o: IOption[]) => {
      options.value = o;
    };

    const setOption = (key: string, value: any) => {
      const index = options.value.findIndex((o) => o.key === key);
      if (index === -1) {
        options.value.push({ key, value });
      } else {
        options.value[index].value = value;
      }
    };

    const getOption = (key: string) => {
      return options.value.find((o) => o.key === key)?.value;
    };

    const fetchOptions = () => {
      return homeFetch("settings/options").then((response) => {
        if (response.data?.data?.options) setOptions(response.data.data?.options);
      });
    };

    const fetchOption = (key: string) => {
      return homeFetch(`option/${key}`).then((response) => {
        setOption(key, response.data);
      });
    };

    const updateOptions = (newOptions: IOption[]) => {
      return homeFetch("user/options", {
        method: "POST",
        body: JSON.stringify(newOptions),
      }).then((response) => {
        if (response.data?.data?.options) setOptions(response.data.data?.options);
      });
    };

    const updateOption = (key: string, value: any) => {
      return homeFetch(`settings/option`, {
        method: "POST",
        body: JSON.stringify({ key, value: String(value) }),
      }).then((response) => {
        const returnedValue = response.data?.data?.value;
        setOption(key, returnedValue);
      });
    };

    const hasWorkspaceSidebar = computed(() => {
      return getOption("workspaceSidebar") === true;
    });

    return {
      options,
      setOptions,
      setOption,
      getOption,
      fetchOptions,
      fetchOption,
      updateOptions,
      updateOption,
      hasWorkspaceSidebar,
    };
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.localStorage(),
    },
  }
);
