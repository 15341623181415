<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <GlobalLoader />
  <Toast />
  <template v-if="isUserLoggedIn">
    <BoardingModal />
    <NewShipmentWizard />
    <FormWizard />
    <InviteUsersWizard />
    <PrintWizard />
    <NewTicketWizard />

    <ClientOnly>
      <WorkspaceSettingsModal />
      <UserSettingsModal />
      <ModalLegal
        v-if="hasPendingLegal"
        :pending-document="pendingDocument"
        :loading="documentsLoading"
        @update-accept="(val) => acceptDocument(val)"
      />
      <ModalPresentation v-else-if="showPresentationModal" />
      <DialogModal />
      <GlobalSearch />
    </ClientOnly>
  </template>
</template>
<script setup lang="ts">
  import { flare } from "@flareapp/js";
  import QueryString from "qs";
  import type { PendingDocument } from "~/types/legal";

  import "@fontsource-variable/dm-sans";

  const { t } = useI18n();
  const { isUserLoggedIn, getLegalDocuments, isExternalAccess } = useAuth();
  const workspaceStore = useWorkspacesStore();
  const { setWorkspace } = workspaceStore;
  const { accountId, user, userName, userEmail, workspaces, hasMultipleWorkspaces } = storeToRefs(workspaceStore);
  const importSocketChannel = ref("");
  const hasPendingLegal = ref(false);
  const pendingDocument = ref<PendingDocument>();
  const pendingDocuments = ref<PendingDocument[]>();
  const documentsLoading = ref(false);

  const showPresentationModal = computed(() => {
    if (isExternalAccess.value) return false;
    return true;
  });

  //add current os to the body class for styling ie. ios, android, windows, mac
  onMounted(() => {
    if (import.meta.client) {
      document.body.classList.add(getOS().toLowerCase());
    }
  });

  const importerStates = ["awaiting", "failed", "done"];
  const { getEchoClient, destroyEchoClient, hasEchoClient } = useEcho();
  const setupImporterSockets = () => {
    importSocketChannel.value = `order_import.${accountId.value}`;
    const echo = getEchoClient();
    importerStates.forEach((key) => {
      echo.private(importSocketChannel.value).listen(`.${key}`, (e) => {
        switch (key) {
          case "done":
            onImportDone(e);
            break;
          case "awaiting":
            onImportAwaiting(e);
            break;
          case "failed":
            onImportFailed(e);
            break;
        }
      });
    });
  };

  const destroyImporterSockets = () => {
    if (!hasEchoClient.value) return;
    const echo = getEchoClient();
    importerStates.forEach((key) => {
      echo.private(importSocketChannel.value).stopListening(`.${key}`);
    });

    importSocketChannel.value = "";
    destroyEchoClient();
  };

  watch(isUserLoggedIn, (value) => {
    if (import.meta.server) return;
    if (value) {
      if (user.value)
        flare.addContextGroup("user", {
          email: userEmail.value,
          name: userName.value,
          accountId: accountId.value,
        });

      setupImporterSockets();

      checkAuthCookies().then(() => {
        if (isExternalAccess.value) return;
        getLegalDocuments().then((data) => {
          if (!data) return;

          if (data.pending_documents && data.pending_documents.length > 0) {
            hasPendingLegal.value = true;
            pendingDocuments.value = data.pending_documents;
            pendingDocument.value = data.pending_documents[0];
          }
        });
      });
    } else destroyImporterSockets();
  });

  const checkAuthCookies = () => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const hrToken = document.cookie.split("; ").find((row) => row.startsWith("hr_token="));
        const hrTokenExpire = document.cookie.split("; ").find((row) => row.startsWith("hr_token_expire="));

        if (hrToken && hrTokenExpire) {
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  };

  const acceptDocument = (document) => {
    if (document) {
      documentsLoading.value = true;
      homeFetch(`customers/legal-documents/${document.id}`, {
        method: "POST",
      })
        .then(() => {
          documentsLoading.value = false;
          pendingDocuments.value = pendingDocuments.value?.filter((item) => item.id !== document.id);
          if (pendingDocuments.value?.length !== 0) {
            pendingDocument.value = pendingDocuments.value ? pendingDocuments.value[0] : undefined;
          } else {
            hasPendingLegal.value = false;
          }
        })
        .finally(() => {});
    }
  };

  onUnmounted(() => {
    destroyImporterSockets();
  });

  const onImportDone = () => {
    const base = useRouter().resolve({ name: "shipments" });
    const url =
      base.path +
      "?" +
      QueryString.stringify({ filters: { state: { $or: [{ $eq: "done" }] } } }, { encodeValuesOnly: true });

    $toast.add({
      group: "shipment_import_done",
      title: t("new_shipment_generated"),
      actions: [
        {
          text: t("do_view"),
          handler: () => {
            useRouter().push(url);
          },
        },
      ],
    });
  };

  const onImportAwaiting = () => {
    const base = useRouter().resolve({
      name: "shipments-import-list",
    });
    const url =
      base.path +
      "?" +
      QueryString.stringify({ filters: { state: { $or: [{ $eq: "awaiting" }] } } }, { encodeValuesOnly: true });

    $toast.add({
      group: "shipment_imported",
      title: t("new_shipment_imported"),
      actions: [
        {
          text: t("do_view"),
          handler: () => {
            useRouter().push(url);
          },
        },
      ],
    });
  };

  const onImportFailed = () => {
    const base = useRouter().resolve({
      name: "shipments-import-list",
    });
    const url =
      base.path +
      "?" +
      QueryString.stringify({ filters: { state: { $or: [{ $eq: "failed" }] } } }, { encodeValuesOnly: true });

    $toast.add({
      group: "shipment_import_failed",
      title: t("new_shipment_failed"),
      actions: [
        {
          text: t("do_view"),
          handler: () => {
            useRouter().push(url);
          },
        },
      ],
    });
  };

  const onKeyPress = (e: KeyboardEvent) => {
    if (!/\d/.test(e.key)) return;
    if (!hasMultipleWorkspaces.value) return;
    if (e.key === "0") return;

    //if development environment, use cmd+k to open the modal
    if (getOS() === "Mac") {
      if (e.metaKey) {
        setWorkspace(workspaces.value[parseInt(e.key) - 1]);
        e.preventDefault();
      }
    } else {
      if (e.ctrlKey) {
        setWorkspace(workspaces.value[parseInt(e.key) - 1]);
        e.preventDefault();
      }
    }
  };

  watch(
    hasMultipleWorkspaces,
    (value) => {
      if (value) {
        document.addEventListener("keydown", onKeyPress);
      } else {
        document.removeEventListener("keydown", onKeyPress);
      }
    },
    { immediate: true }
  );

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", onKeyPress);
  });
</script>
<style>
  html,
  body,
  #__nuxt,
  #__layout {
    height: 100% !important;
    width: 100% !important;
  }
</style>
