<template>
  <div class="relative flex w-full items-center gap-3">
    <Button type="button" :size="ComponentSize.sm" :disabled="isAtStart" icon-only @click="scroll('minus')">
      <Icon src="arrow_left" :class="isAtStart ? 'text-disabled' : 'text-foreground-secondary'" />
    </Button>
    <div
      id="slider"
      ref="slider"
      class="group flex w-full snap-x snap-mandatory gap-5 overflow-x-auto scroll-smooth transition-all duration-300"
      @scroll="handleScroll"
    >
      <template v-for="(item, index) in items" :key="index">
        <slot name="item" :item="item" :index="index">
          <div
            class="h-auto min-w-[180px] cursor-pointer snap-start rounded-lg px-3 py-4 transition-all duration-300"
            :class="selectedIndex == index ? 'shadow-inset-01-01' : 'shadow-inset-00-01'"
            :data-id="item.id"
            @click="() => updateSelection(item, index)"
          >
            <slot name="content" :item="item" />
          </div>
        </slot>
      </template>
    </div>
    <Button type="button" :size="ComponentSize.sm" :disabled="isAtEnd" icon-only @click="scroll('add')">
      <Icon src="arrow_right" :class="isAtEnd ? 'text-disabled' : 'text-foreground-secondary'" />
    </Button>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  // Definitions
  defineOptions({
    name: "Slider",
  });

  const props = defineProps<{
    items: Array<any>;
    deselectItem: boolean;
    preSelect?: boolean;
  }>();
  const emit = defineEmits(["update:modelValue"]);

  // Data
  const slider = useTemplateRef<HTMLElement>("slider");
  const selectedIndex = ref<number>();
  const isAtStart = ref<boolean>(true);
  const isAtEnd = ref<boolean>(false);

  // Functions
  const scroll = (direction: string) => {
    if (!slider.value) return;
    const dirMult = direction === "add" ? 1 : -1;
    slider.value.scrollLeft += slider.value?.clientWidth * dirMult;
  };

  const scrollToIndex = (index: number) => {
    if (!slider.value) return;
    const item = slider.value.children[index] as HTMLElement;
    slider.value.scrollLeft = item.offsetLeft;
  };

  const updateSelection = (item: object, index: number, scroll?: boolean) => {
    emit("update:modelValue", item);
    selectedIndex.value = index;

    if (scroll) {
      scrollToIndex(index);
    }
  };

  const handleScroll = () => {
    if (!slider.value) return;
    isAtStart.value = slider.value.scrollLeft === 0;
    isAtEnd.value = slider.value.scrollLeft + slider.value.clientWidth === slider.value.scrollWidth;
  };

  // Lifecycle
  onMounted(() => {
    if (props.preSelect) {
      updateSelection(props.items[0], 0, true);
    }
  });

  defineExpose({
    updateSelection,
  });
</script>

<style lang="css" scoped>
  #slider::-webkit-scrollbar {
    display: none;
  }

  #slider {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
</style>
