export enum RouteName {
  "shipments" = "shipments",
  "shipments_details" = "shipments-id",
  "shipments_label" = "shipments-id-label",
  "shipments_import" = "shipments-import",
  "shipments_import_list" = "shipments-import-list",
  "returns" = "returns",
  "tickets" = "tickets",
  "tickets_details" = "tickets-id",
  "sortings" = "sortings",
  "dashboard" = "dashboard",
  "prices" = "prices",
  "documents" = "documents",
  "invoices" = "invoices",
  "checkouts_test" = "checkouts-test",
  "login" = "auth-login",
  "register" = "auth-register",
  "reset" = "auth-reset",
  "reset_password" = "auth-reset-password",
  "logout" = "auth-logout",
  "purchase_orders" = "purchase-orders",
  "purchase_orders_details" = "purchase-orders-id",
  "seafreight" = "seafreight",
  "seafreight_details" = "seafreight-id",
  "containers" = "containers",
  "containers_details" = "containers-id",
  "cookies" = "cookies",
  "click_collect" = "clickcollect",
}
