<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <FlowSection :title="null">
      <div class="grid auto-cols-fr grid-cols-2 gap-3">
        <div class="col-span-2 flex flex-col">
          <VueDraggableNext
            v-model="workspaces"
            item-key="subdomain"
            handle=".cursor-grab"
            ghost-class="ghost"
            chosen-class="dragging"
            animation="150"
            direction="vertical"
            :disabled="workspaces.length <= 1"
            @end="onDragEnd"
          >
            <LayoutNavigationWorkspaceEntry
              v-for="(workspace, index) in workspaces"
              :key="workspace.subdomain || workspace.account.account_id"
              :workspace="workspace"
              :index="index"
              :draggable="workspaces.length > 1"
              :hover-effect="false"
              :clickable="false"
            >
              <template #actions>
                <div class="text-quarterary text-sm">{{ workspace.userRoles[0] }}</div>
                <div class="mx-5">
                  <Button
                    :size="ComponentSize.sm"
                    class="equal-width-btn"
                    :disabled="index === 0"
                    @click.stop="onSetDefaultWorkspace(workspace)"
                  >
                    {{ index === 0 ? t("default_workspace") : t("set_as_default") }}
                  </Button>
                </div>
              </template>
            </LayoutNavigationWorkspaceEntry>
          </VueDraggableNext>
        </div>
      </div>
    </FlowSection>
  </div>
</template>

<script setup lang="ts">
  import { useOptionsStore } from "@/stores/Options";
  import { ComponentSize } from "~/types/global";
  import { storeToRefs } from "pinia";
  import { onMounted, toRef } from "vue";
  import { VueDraggableNext } from "vue-draggable-next";
  import { useI18n } from "vue-i18n";
  import type { ISettingsFlow } from "../types";
  import type { IWorkspace } from "~/types/auth";

  const optionsStore = useOptionsStore();

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
      loading?: boolean;
    }>(),
    {
      currentHeader: null,
    }
  );

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);

  function onSetDefaultWorkspace(workspace: IWorkspace) {
    setLoading(true);

    const index = workspaces.value.findIndex((w) => w.account.account_id === workspace.account.account_id);

    if (index > -1) {
      const [movedWorkspace] = workspaces.value.splice(index, 1);
      workspaces.value.unshift(movedWorkspace);

      const newOrder = workspaces.value.map((w) => w.account.account_id);

      optionsStore.updateOption("workspace_order", JSON.stringify(newOrder)).finally(() => setLoading(false));
    }
  }

  const { t } = useI18n();
  const store = useWorkspacesStore();
  const { workspaces } = storeToRefs(store);

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  onMounted(() => {
    goToCurrentHeader();
  });

  // Equal width buttons
  onMounted(() => {
    const buttons = document.querySelectorAll(".equal-width-btn");
    let maxWidth = 0;
    buttons.forEach((btn) => {
      const element = btn as HTMLElement;
      const btnWidth = element.getBoundingClientRect().width;
      if (btnWidth > maxWidth) {
        maxWidth = btnWidth;
      }
    });
    buttons.forEach((btn) => {
      (btn as HTMLElement).style.width = `${maxWidth}px`;
    });
  });

  function onDragEnd() {
    const newOrder = workspaces.value.map((workspace) => workspace.account.account_id);

    optionsStore.updateOption("workspace_order", JSON.stringify(newOrder));
  }
</script>

<script lang="ts">
  export const useWorkspaceAndRolesSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const icon = "regular/Browsers";
    const title = t("user_settings.workspace_roles.title");
    const pageTitle = t("user_settings.workspace_roles.title");
    const key = "workspaces_roles";

    return {
      icon,
      pageTitle,
      title,
      key,
    };
  };
</script>

<style>
  .ghost {
    background-color: var(--background-color-00);
    height: 85%;
    * {
      display: none;
    }
  }

  .dragging .cursor-grab {
    background-color: var(--background-color-02);
  }
</style>
